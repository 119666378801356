@import url('https://fonts.googleapis.com/css?family=Montserrat');


.stipop_logo {
    width: 270px;
    height: 95px;
    object-fit: contain;
}

.To-continue-Please {
    width: 439px;
    height: 56px;
    font-family: Montserrat;
    font-size: 17px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.65;
    letter-spacing: normal;
    text-align: center;
    color: #000000;
}

.To-continue-Please2 {
    width: 439px;
    height: 56px;
    font-family: Montserrat;
    font-size: 17px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.65;
    letter-spacing: normal;
    text-align: center;
    color: #000000;
}

.sticker_image {
    margin-top: 21px;
    width: 370px;
    height: 370px;
    border: solid 1px rgba(151, 151, 151, 0);
}

.non_sticker_image {
    margin-top: 21px;
    width: 370px;
    height: 370px;
    border: solid 1px rgba(151, 151, 151, 0);
    background-color: #e9e9e9;
}

.name {
    margin-top: 13px;
    width: auto;
    // white-space: nowrap;
    font-family: Montserrat;
    font-size: 36px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.11;
    letter-spacing: normal;
    text-align: center;
    color: #000000;
}

.Artist {
    // align-self: flex-start;
    // width: 81px;
    height: 28px;
    font-family: Montserrat;
    font-size: 18px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.56;
    letter-spacing: 0.7px;
    text-align: center;
    color: #424242;
}

.Rectangle {
    margin-top: 22px;
    align-self: center;
    padding-left: 10px;
    padding-right: 10px;
    // margin-top: 100px;
    // width: 110px;
    height: 34px;
    border-radius: 17px;
    border: solid 1px rgba(151, 151, 151, 0);
    background-color: #e9e9e9;
}

.Rectangle-View {
    margin-top: 79px;
    width: 570px;
    height: 66px;
    border-radius: 33px;
    box-shadow: 2px 2px 4px 0 rgba(0, 0, 0, 0.2);
    border: solid 1px rgba(255, 59, 148, 0);
    background-color: #ff3b94;
}

.Rectangle-View-Pressed {
    margin-top: 79px;
    width: 570px;
    height: 66px;
    opacity: 0.6;
    border-radius: 33px;
    box-shadow: 2px 2px 4px 0 rgba(0, 0, 0, 0.12);
    background-color: #ff3b94;
}

.Rectangle-Pressed {
    width: 570px;
    height: 66px;
    opacity: 0.6;
    border-radius: 33px;
    box-shadow: 2px 2px 4px 0 rgba(0, 0, 0, 0.12);
    border: solid 1px #ff3b94;
    background-color: #ffffff;
}

.Rectangle-GoWeb {
    width: 570px;
    height: 66px;
    border-radius: 33px;
    box-shadow: 2px 2px 4px 0 rgba(0, 0, 0, 0.2);
    border: solid 2px #ff3b94;
    background-color: #ffffff;
}

.Rectangle-Download {
    width: 570px;
    height: 66px;
    border-radius: 33px;
    box-shadow: 2px 2px 4px 0 rgba(0, 0, 0, 0.2);
    border: solid 2px #ff3b94;
    background-color: #ffffff;
}

.See-more-at-Stipop {
    width: 250px;
    height: 34px;
    font-family: Montserrat;
    font-size: 18px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.89;
    letter-spacing: 0.3px;
    text-align: center;
    color: #ffffff;
}

.Pink-Text {
    width: 250px;
    height: 34px;
    font-family: Montserrat;
    font-size: 18px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.89;
    letter-spacing: 0.3px;
    text-align: center;
    color: #ff3b94;
}

.Text-Pressed {
    width: 250px;
    height: 30px;
    opacity: 0.6;
    font-family: Montserrat;
    font-size: 16px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.88;
    letter-spacing: normal;
    text-align: center;
    color: #ff3b94;
}

@media screen and (max-width: 600px) {
    .stipop_logo {
        width: 150px;
        height: 95px;
        object-fit: contain;
    }

    .To-continue-Please {
        margin-top: 36px;
        // width: auto;
        // height: 44px;
        font-family: Montserrat;
        font-size: 14px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.57;
        letter-spacing: normal;
        text-align: center;
        color: #000000;
    }

    .sticker_image {
        margin-top: 18px;
        width: 144px;
        height: 144px;
        border: solid 1px rgba(151, 151, 151, 0);
        // background-color: #e9e9e9;
    }

    .non_sticker_image {
        margin-top: 18px;
        width: 144px;
        height: 144px;
        border: solid 1px rgba(151, 151, 151, 0);
        background-color: #e9e9e9;
    }

    .name {
        width: auto;
        height: 35px;
        font-family: Montserrat;
        font-size: 21px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.71;
        letter-spacing: normal;
        text-align: center;
        color: #000000;
    }

    .Artist {
        // align-self: flex-start;
        // width: 81px;
        width: auto;
        height: auto;
        font-family: Montserrat;
        font-size: 10px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: center;
        color: #424242;
    }

    .Rectangle {
        align-self: center;
        padding-left: 10px;
        padding-right: 10px;
        // margin-top: 100px;
        // width: auto;
        height: 14px;
        border-radius: 17px;  
        border: solid 1px rgba(151, 151, 151, 0);
        background-color: #e9e9e9;
    }

    .Rectangle-View {
        margin-top: 36px;
        width: 229px;
        height: 37px;
        border-radius: 17px;
        box-shadow: 2px 2px 4px 0 rgba(0, 0, 0, 0.12);
        border: solid 1px rgba(255, 59, 148, 0);
        background-color: #ff3b94;
    }

    .Rectangle-View-Pressed {
        margin-top: 36px;
        width: 229px;
        height: 37px;
        opacity: 0.6;
        border-radius: 17px;
        box-shadow: 2px 2px 4px 0 rgba(0, 0, 0, 0.12);
        background-color: #ff3b94;
    }

    .See-more-at-Stipop {
        height: 30px;
        font-family: Montserrat;
        font-size: 16px;
        font-weight: 600;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.88;
        letter-spacing: normal;
        text-align: center;
        color: #ffffff;
    }

    .Rectangle-Pressed {
        margin-top: 24px;
        width: 229px;
        height: 37px;
        opacity: 0.6;
        border-radius: 17px;
        box-shadow: 2px 2px 4px 0 rgba(0, 0, 0, 0.12);
        border: solid 1px #ff3b94;
        background-color: #ffffff;
    }

    .Rectangle-Download {
        margin-top: 24px;
        width: 229px;
        height: 37px;
        border-radius: 17px;
        box-shadow: 2px 2px 4px 0 rgba(0, 0, 0, 0.12);
        border: solid 1px #ff3b94;
        background-color: #ffffff;
    }

    .Pink-Text {
        // width: auto;
        height: 30px;
        font-family: Montserrat;
        font-size: 16px;
        font-weight: 600;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.88;
        letter-spacing: normal;
        text-align: center;
        color: #ff3b94;
    }
}

